import {combineReducers} from "redux"
import { reducer as formReducer } from 'redux-form'
import appReducer from "./reducers/appReducer"
import authReducer from "./reducers/authReducer"
import customerReducer from "./reducers/customerReducer"
import orderReducer from "./reducers/orderReducer"
import orderDetailReducer from "./reducers/orderDetailReducer"
import workflowReducer from "./reducers/workflowReducer"
import tableReducer from "./reducers/tableReducer";
import employeeReducer from "./reducers/employeeReducer";
import userReducer from "./reducers/userReducer";
import settingReducer from "./reducers/settingReducer"
import userRoleReducer from "./reducers/userRoleReducer";
import userRolePermissionReducer from "./reducers/userRolePermissionReducer";

const rootReducer = combineReducers({
    appStore: appReducer,
    authStore: authReducer,
    customerStore: customerReducer,
    orderStore: orderReducer,
    orderDetailStore: orderDetailReducer,
    workflowStore: workflowReducer,
    tableStore: tableReducer,
    employeeStore: employeeReducer,
    userStore: userReducer,
    roleStore: userRoleReducer,
    permissionStore: userRolePermissionReducer,
    settingStore: settingReducer,
    form: formReducer
})

export default rootReducer