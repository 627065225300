import axios from "./api/api"
import {CLEAR_AUTH, LOGIN_AUTH_SUCCESS} from "./types/authTypes"
import {hideLoaderAction, showAlertAction, showLoaderAction} from "./appAction"
import {authResult} from "../routes/route-urls";

function parseJwt (token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
    return JSON.parse(jsonPayload)
}

function fillLocalStorage(token, parsedJwt) {
    localStorage.setItem('token_api', token)
    localStorage.setItem('token_expire', parsedJwt.exp)
    localStorage.setItem('user_name', parsedJwt.user.name)
    localStorage.setItem('user_roles', parsedJwt.user.roles)
    axios.defaults.headers.common.Authorization = token
}

export function signInAction(data) {
    return async dispatch => {
        dispatch(showLoaderAction())
        return await axios.post('/login', data)
            .then((response) => {
                const parsedJwt = parseJwt(response.token)
                fillLocalStorage(response.token, parsedJwt)
                dispatch({type: LOGIN_AUTH_SUCCESS, payload: parsedJwt})
                dispatch(showAlertAction('Успешная авторизация', 'success'))
            })
            .catch((error) => {
                localStorage.clear()
                dispatch({type: CLEAR_AUTH})
                dispatch(showAlertAction(error, 'error'))
            }).finally(dispatch(hideLoaderAction()))
    }
}

export function signUpAction(data, history) {
    return async dispatch => {
        dispatch(showLoaderAction())
        return await axios.post('/register', data)
            .then((response) => {
                const parsedJwt = parseJwt(response.token)
                fillLocalStorage(response.token, parsedJwt)
                dispatch({type: LOGIN_AUTH_SUCCESS, payload: parsedJwt})
                dispatch(showAlertAction('Успешная авторизация', 'success'))
            })
            .catch((error) => {
                localStorage.clear()
                dispatch({type: CLEAR_AUTH})
                dispatch(showAlertAction(error, 'error'))
            }).finally(dispatch(hideLoaderAction()))
    }
}

export function signOutAction() {
    return dispatch => {
        localStorage.clear()
        dispatch({type: CLEAR_AUTH})
    }
}