import React from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import {useDispatch} from "react-redux"
import {signUpAction} from "../../../service/actions/authAction"
import {Field, initialize, reduxForm} from "redux-form"
import InputTextField from "../../App/common/redux-form-fields/InputTextField"
import {homePageRoute, signInRoute} from "../../../service/routes/route-urls"
import Avatar from "@material-ui/core/Avatar"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import Typography from "@material-ui/core/Typography"
import styles from "../styles"
import signUpFormValidator from "./signUpFormValidator"
import {ButtonGroup} from "@material-ui/core";

const form = 'signUp'

const SignUpForm = (props) => {
    const classes = styles()
    const dispatch = useDispatch()

    const {match, history} = props
    const {handleSubmit, pristine, reset, submitting} = props
/*
    const data = {
        title: 'Сергей',
        surname: 'Сергеевич',
        email: 'admin@rvkvent.ru',
        invite: 'rest_API',
        password: '09011983',
        password2: '09011983',
        accept: '1'
    }

    React.useEffect(() => {
        dispatch(initialize(form, data))
    }, [dispatch])
*/
    const submitHandler = (values) => {
        dispatch(signUpAction(values, history))
    }

    return (
        <>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5" align={"center"} >
                Регистрация
            </Typography>
            <form onSubmit={handleSubmit(submitHandler)} className={classes.form}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Field
                            required
                            name="title"
                            component={InputTextField}
                            label="Имя и Отчество"
                            type="text"
                            autoComplete="off"
                            margin="normal"
                            fullWidth
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Field
                            required
                            name="email"
                            component={InputTextField}
                            label="Емайл"
                            type="email"
                            autoComplete="off"
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Field
                            required
                            name="password"
                            component={InputTextField}
                            label="Пароль"
                            type="password"
                            autoComplete="off"
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Field
                            required
                            name="password2"
                            component={InputTextField}
                            label="Подтвердите пароль"
                            type="password"
                            autoComplete="off"
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Field
                            required
                            name="invite"
                            component={InputTextField}
                            label="Инвайт-код"
                            type="password"
                            autoComplete="off"
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox value="accept" color="primary" />}
                            label="Согласен(а) на получение информации по эл.почте"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroup variant="contained" color="primary" aria-label="home page" fullWidth>
                            <Button type="submit">Регистрация</Button>
                            <Button type="button" onClick={() => props.history.push(homePageRoute)}>Назад</Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default reduxForm({
    form: form,
    signUpFormValidator,
})(SignUpForm)