import {
    API_ERROR,
    HIDE_LOADER,
    SHOW_LOADER,
    SET_ALERT,
    REMOVE_ALERT
} from "../../service/actions/types/appTypes"

const initialState = {
    loading: false,
    error: null,
    alerts: [],
}

const appReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case API_ERROR:
            return {...state, error: action.payload}
        case SHOW_LOADER:
            return {...state, loading: true}
        case HIDE_LOADER:
            return {...state, loading: false}
        case SET_ALERT:
            return {...state, alerts: [...state.alerts, action.payload]}
        case REMOVE_ALERT:
            return {...state, alerts: state.alerts.filter(alert => alert.id !== action.payload)}
        default:
            return state
    }
}

export default appReducer