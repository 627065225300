import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import styles from "./deps/styles"

export default function AuthLayout({children}) {

    const classes = styles()

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={false} sm={6} md={8} className={classes.image}/>
            <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    {children}
                </div>
            </Grid>
        </Grid>
    )
}