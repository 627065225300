export const CLEAR_CUSTOMERS                    = 'CUSTOMER/CLEAR_CUSTOMERS'
export const CLEAR_CUSTOMERS_OPTIONS            = 'CUSTOMER/CLEAR_CUSTOMERS_OPTIONS'
export const CLEAR_CUSTOMER                     = 'CUSTOMER/CLEAR_CUSTOMER'
export const REQUEST_CUSTOMERS_SUCCESS          = 'CUSTOMER/REQUEST_CUSTOMERS_SUCCESS'
export const REQUEST_CUSTOMERS_FAIL             = 'CUSTOMER/REQUEST_CUSTOMERS_FAIL'
export const REQUEST_CUSTOMERS_OPTIONS_SUCCESS  = 'CUSTOMER/REQUEST_CUSTOMERS_OPTIONS_SUCCESS'
export const REQUEST_CUSTOMERS_OPTIONS_FAIL     = 'CUSTOMER/REQUEST_CUSTOMERS_OPTIONS_FAIL'
export const REQUEST_CUSTOMER_SUCCESS           = 'CUSTOMER/REQUEST_CUSTOMER_SUCCESS'
export const REQUEST_CUSTOMER_FAIL              = 'CUSTOMER/REQUEST_CUSTOMER_FAIL'
export const CREATE_CUSTOMER_SUCCESS            = 'CUSTOMER/CREATE_CUSTOMER_SUCCESS'
export const CREATE_CUSTOMER_FAIL               = 'CUSTOMER/CREATE_CUSTOMER_FAIL'
export const UPDATE_CUSTOMER_SUCCESS            = 'CUSTOMER/UPDATE_CUSTOMER_SUCCESS'
export const UPDATE_CUSTOMER_FAIL               = 'CUSTOMER/UPDATE_CUSTOMER_FIL'
export const DELETE_CUSTOMER_SUCCESS            = 'CUSTOMER/DELETE_CUSTOMER_SUCCESS'
export const DELETE_CUSTOMER_FAIL               = 'CUSTOMER/DELETE_CUSTOMER_FAIL'
export const CHANGE_FILTER_CUSTOMER             = 'CUSTOMER/CHANGE_FILTER'