import {
    CLEAR_SETTINGS,
    REQUEST_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_SUCCESS,
} from "../../service/actions/types/settingTypes"

const initialState = {
    settings: null,
}

const settingReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CLEAR_SETTINGS:
            return {...state, settings: null}
        case REQUEST_SETTINGS_SUCCESS:
            return {...state, settings: action.payload}
        case UPDATE_SETTINGS_SUCCESS:
            return {...state, settings: action.payload}
        default:
            return state
    }
}

export default settingReducer