import {
    CLEAR_ORDER_DETAILS, CLEAR_ORDER_DETAIL, REQUEST_ORDER_DETAILS_SUCCESS, REQUEST_ORDER_DETAIL_SUCCESS,
    CREATE_ORDER_DETAIL_SUCCESS, UPDATE_ORDER_DETAIL_SUCCESS,DELETE_ORDER_DETAIL_SUCCESS,
    CALC_ORDER_DETAIL_SUCCESS, CLEAR_ORDER_DETAIL_RESULT, CREATE_ORDER_DETAIL_FAIL, COPY_ORDER_DETAIL_SUCCESS,
    MULTIPLE_DELETE_ORDER_DETAIL_SUCCESS, CHANGE_ID_ORDER_DETAIL_DELETE,CHANGE_ORDER_DETAIL_SUCCESS,
    CLEAR_CHANGED_ORDER_DETAIL
} from "../../service/actions/types/orderDetailTypes"

const initialState = {
    details: [],
    detail: null,
    result: null,
    changed: null,
}

const orderDetailReducer = (state = initialState, action = {}) => {

    switch (action.type) {
        case CLEAR_CHANGED_ORDER_DETAIL:
            return {...state, changed: null}
        case CLEAR_ORDER_DETAIL:
            return {...state, detail: null}
        case CLEAR_ORDER_DETAILS:
            return {...state, details: [], deleteIds: [], result: null}
        case REQUEST_ORDER_DETAILS_SUCCESS:
            return {...state, details: [...action.payload]}
        case REQUEST_ORDER_DETAIL_SUCCESS:
            return {...state, detail: action.payload}
        /*case COPY_ORDER_DETAIL_SUCCESS:
            const copyId = state.details.findIndex((detail) => detail.id === action.payload.copyId)
            return {
                ...state, details: [
                    ...state.details.slice(0, (copyId + 1)),
                    action.payload.data,
                    ...state.details.slice(copyId + 1),
                ]
            }*/
        case CREATE_ORDER_DETAIL_SUCCESS:
            return {
                ...state,
                changed: action.payload.id,
                details: state.details.concat([action.payload])
            }
        case UPDATE_ORDER_DETAIL_SUCCESS:
            const updateIdx = state.details.findIndex((detail) => detail.id === action.payload.id)
            return {
                ...state, details: [
                    ...state.details.slice(0, updateIdx),
                    action.payload,
                    ...state.details.slice(updateIdx + 1),
                ]
            }
        case MULTIPLE_DELETE_ORDER_DETAIL_SUCCESS:
            let newArray = state.details
            for (let i = 0; i < action.payload.length; ++i) {
                newArray.filter((detail, number) => {
                    if (detail.id === action.payload[i]) {
                        newArray.splice(number, 1);
                    }
                })
            }
            return {...state, details: [...newArray]}
        case DELETE_ORDER_DETAIL_SUCCESS:
            return {...state, details: state.details.filter(detail => detail.id !== action.payload)}
        case CLEAR_ORDER_DETAIL_RESULT:
            return {...state, result: null}
        case CALC_ORDER_DETAIL_SUCCESS:
            return {...state, result: action.payload}
        case CHANGE_ID_ORDER_DETAIL_DELETE:
            const changedIndex = state.details.findIndex((detail) => detail.id === action.payload)
            let changedDetail = state.details[changedIndex]
            changedDetail.changedDelete = !changedDetail.changedDelete
            return {
                ...state, details: [
                    ...state.details.slice(0, changedIndex),
                    changedDetail,
                    ...state.details.slice(changedIndex + 1),
                ]
            }
        case CHANGE_ORDER_DETAIL_SUCCESS:
            return {...state, changed: action.payload}

        default:
            return state
    }
}

export default orderDetailReducer