import React from "react"
import Zoom from '@material-ui/core/Zoom'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import makeStyles from "@material-ui/core/styles/makeStyles"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import Fab from "@material-ui/core/Fab"

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}))

const ScrollTop = ({anchor}) => {
    const classes = useStyles()
    const trigger = useScrollTrigger({
        //target: window ? window() : undefined,
        disableHysteresis: false,
        threshold: 150,
    })

    const handleClick = () => {
        if (anchor) {
            anchor.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }

    return (
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation" className={classes.root}>
                <Fab color="primary" size="large" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </div>
        </Zoom>
    )
}

export default ScrollTop