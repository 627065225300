export const CLEAR_CHANGED_ORDER_DETAIL             = 'DETAIL/CLEAR_CHANGED_ORDER_DETAIL'
export const CLEAR_ORDER_DETAILS                    = 'DETAIL/CLEAR_ORDER_DETAILS'
export const CLEAR_ORDER_DETAIL                     = 'DETAIL/CLEAR_ORDER_DETAIL'
export const REQUEST_ORDER_DETAILS_SUCCESS          = 'DETAIL/REQUEST_ORDER_DETAILS_SUCCESS'
export const REQUEST_ORDER_DETAILS_FAIL             = 'DETAIL/REQUEST_ORDER_DETAILS_FAIL'
export const REQUEST_ORDER_DETAIL_SUCCESS           = 'DETAIL/REQUEST_ORDER_DETAIL_SUCCESS'
export const REQUEST_ORDER_DETAIL_FAIL              = 'DETAIL/REQUEST_ORDER_DETAIL_FAIL'
export const CHANGE_ORDER_DETAIL_SUCCESS            = 'DETAIL/CHANGE_ORDER_DETAIL_SUCCESS'
export const COPY_ORDER_DETAIL_SUCCESS              = 'DETAIL/COPY_ORDER_DETAIL_SUCCESS'
export const COPY_ORDER_DETAIL_FAIL                 = 'DETAIL/COPY_ORDER_DETAIL_FAIL'
export const CREATE_ORDER_DETAIL_SUCCESS            = 'DETAIL/CREATE_ORDER_DETAIL_SUCCESS'
export const CREATE_ORDER_DETAIL_FAIL               = 'DETAIL/CREATE_ORDER_DETAIL_FAIL'
export const UPDATE_ORDER_DETAIL_SUCCESS            = 'DETAIL/UPDATE_ORDER_DETAIL_SUCCESS'
export const UPDATE_ORDER_DETAIL_FAIL               = 'DETAIL/UPDATE_ORDER_DETAIL_FAIL'
export const DELETE_ORDER_DETAIL_SUCCESS            = 'DETAIL/DELETE_ORDER_DETAIL_SUCCESS'
export const DELETE_ORDER_DETAIL_FAIL               = 'DETAIL/DELETE_ORDER_DETAIL_FAIL'
export const MULTIPLE_DELETE_ORDER_DETAIL_SUCCESS   = 'DETAIL/MULTIPLE_DELETE_ORDER_DETAIL_SUCCESS'
export const MULTIPLE_DELETE_ORDER_DETAIL_FAIL      = 'DETAIL/DELETE_ORDER_DETAIL_FAIL'
export const CLEAR_ORDER_DETAIL_RESULT              = 'DETAIL/CLEAR_ORDER_DETAIL_RESULT'
export const CALC_ORDER_DETAIL_SUCCESS              = 'DETAIL/CALC_ORDER_DETAIL_SUCCESS'
export const CALC_ORDER_DETAIL_FAIL                 = 'DETAIL/CALC_ORDER_DETAIL_FAIL'
export const CHANGE_ID_ORDER_DETAIL_DELETE          = 'DETAIL/CHANGE_ID_ORDER_DETAIL_DELETE'
export const CHANGE_FILTER_DETAIL_ORDER             = 'DETAIL/CHANGE_ORDER_DETAIL_FILTER'