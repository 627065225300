export const CLEAR_WORKFLOW                 = 'WORKFLOW/CLEAR_WORKFLOW'
export const CLEAR_WORKFLOWS                = 'WORKFLOW/CLEAR_WORKFLOWS'
export const REQUEST_WORKFLOWS_SUCCESS      = 'WORKFLOW/REQUEST_WORKFLOWS_SUCCESS'
export const REQUEST_WORKFLOWS_FAIL         = 'WORKFLOW/REQUEST_WORKFLOWS_FAIL'
export const REQUEST_WORKFLOW_SUCCESS       = 'WORKFLOW/REQUEST_WORKFLOW_SUCCESS'
export const REQUEST_WORKFLOW_FAIL          = 'WORKFLOW/REQUEST_WORKFLOW_FAIL'
export const CREATE_WORKFLOW_SUCCESS        = 'WORKFLOW/CREATE_WORKFLOW_SUCCESS'
export const CREATE_WORKFLOW_FAIL           = 'WORKFLOW/CREATE_WORKFLOW_FAIL'
export const UPDATE_WORKFLOW_SUCCESS        = 'WORKFLOW/UPDATE_WORKFLOW_SUCCESS'
export const UPDATE_WORKFLOW_FAIL           = 'WORKFLOW/UPDATE_WORKFLOW_FAIL'
export const TOGGLE_STATUS_WORKFLOW_SUCCESS = 'WORKFLOW/TOGGLE_STATUS_WORKFLOW_SUCCESS'
export const TOGGLE_STATUS_WORKFLOW_FAIL    = 'WORKFLOW/TOGGLE_STATUS_WORKFLOW_FAIL'
export const CHANGE_WORKFLOW_FIELD_SUCCESS  = 'WORKFLOW/CHANGE_WORKFLOW_FIELD_SUCCESS'
export const CHANGE_WORKFLOW_FIELD_FAIL     = 'WORKFLOW/CHANGE_WORKFLOW_FIELD_FAIL'
export const DELETE_WORKFLOW_SUCCESS        = 'WORKFLOW/DELETE_WORKFLOW_SUCCESS'
export const DELETE_WORKFLOW_FAIL           = 'WORKFLOW/DELETE_WORKFLOW_FAIL'
export const CHANGE_FILTER_WORKFLOW         = 'WORKFLOW/CHANGE_WORKFLOW_FILTER'
export const CHANGE_SORT_WORKFLOW         = 'WORKFLOW/CHANGE_WORKFLOW_SORT'