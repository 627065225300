import {
    CHANGE_FILTER_EMPLOYEE,CLEAR_EMPLOYEE,CLEAR_EMPLOYEES,CREATE_EMPLOYEE_SUCCESS,DELETE_EMPLOYEE_SUCCESS,
    REQUEST_EMPLOYEE_SUCCESS,REQUEST_EMPLOYEES_SUCCESS,UPDATE_EMPLOYEE_SUCCESS
} from "../../service/actions/types/employeeTypes";


const initialState = {
    filter: 1,
    count: 0,
    employees: [],
    employee: null,
}

const employeeReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CLEAR_EMPLOYEES:
            return {...state, items: []}
        case CLEAR_EMPLOYEE:
            return {...state, employee: null}
        case REQUEST_EMPLOYEE_SUCCESS:
            return {...state, employee: action.payload}
        case REQUEST_EMPLOYEES_SUCCESS:
            return {...state, employees: [...action.payload.employees], count: action.payload.count}
        case CREATE_EMPLOYEE_SUCCESS:
            return {...state, employees: state.employees.concat([action.payload])}
        case UPDATE_EMPLOYEE_SUCCESS:
            const updateIdx = state.employees.findIndex( (employee) => employee.id === action.payload.id)
            return {...state, employees: [
                    ...state.employees.slice(0, updateIdx),
                    action.payload,
                    ...state.employees.slice(updateIdx + 1),
                    ]}
        case DELETE_EMPLOYEE_SUCCESS:
            return {...state, employees: state.employees.filter(employees => employees.id !== action.payload)}
        case CHANGE_FILTER_EMPLOYEE:
            return {...state, filter: action.payload}
        default:
            return state
    }
}

export default employeeReducer