import {
    CLEAR_TABLE,
    CLEAR_TABLES,
    CREATE_TABLE_SUCCESS,
    DELETE_TABLE_SUCCESS,
    REQUEST_TABLE_SUCCESS,
    REQUEST_TABLES_SUCCESS,
    UPDATE_TABLE_SUCCESS,
    UPDATE_TABLE_TRACKER_SUCCESS
} from "../../service/actions/types/tableTypes";

const initialState = {
    filter: 1,
    count: 0,
    tables: [],
    table: null,
}

const tableReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CLEAR_TABLES:
            return {...state, items: []}
        case CLEAR_TABLE:
            return {...state, table: null}
        case REQUEST_TABLE_SUCCESS:
            return {...state, table: action.payload}
        case REQUEST_TABLES_SUCCESS:
            return {...state, tables: [...action.payload.tables], count: action.payload.count}
        case CREATE_TABLE_SUCCESS:
            return {...state, tables: state.tables.concat([action.payload])}
        case UPDATE_TABLE_SUCCESS:
            const updateIdx = state.tables.findIndex( (table) => table.id === action.payload.id)
            return {...state, tables: [
                    ...state.tables.slice(0, updateIdx),
                    action.payload,
                    ...state.tables.slice(updateIdx + 1),
                    ]}
        case DELETE_TABLE_SUCCESS:
            return {...state, tables: state.tables.filter(table => table.id !== action.payload)}
        case UPDATE_TABLE_TRACKER_SUCCESS:
            const employeeIdx = state.table.employees.findIndex( (employee) => employee.id === action.payload.employee)
            const employee = state.table.employees[employeeIdx]
            const trackerIdx = employee.trackers.findIndex( (tracker) => tracker.id === action.payload.tracker.id)
            const tracker = state.table.employees[employeeIdx].trackers[trackerIdx]
            console.log('Payload: ', action.payload);
            console.log('Employee: ', employee);
            console.log('Employee Tracker Index: ', trackerIdx);
            console.log('Employee Trackers: ', employee.trackers);
            console.log('Tracker: ', tracker);
            console.log('Tracker ID: ', action.payload.tracker.id);

            return {
                ...state, table: {
                    ...state.table, employees: [
                        ...state.table.employees.slice(0, employeeIdx),
                        {...state.table.employees[employeeIdx], trackers: [
                                ...state.table.employees[employeeIdx].trackers.slice(0, trackerIdx),
                                action.payload.tracker,
                                ...state.table.employees[employeeIdx].trackers.slice(trackerIdx + 1),
                            ]
                        },
                        ...state.table.employees.slice(employeeIdx + 1),
                    ]
                }
            }

        default:
            return state
    }
}

export default tableReducer