const signInFormValidator = (values) => {

    const errors = {}

    if (!values.email)
        errors.email = 'Не корректный формат электронной почты'
    else if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
        errors.email = 'Укажите email'

    if (!values.password)
        errors.password = 'Укажите email'
    else if (values.password.length < 6)
        errors.password = 'Пароль должен содержать не менее 6 символов'

    return errors
}

export default signInFormValidator