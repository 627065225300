import React from "react"
import TextField from "@material-ui/core/TextField"

const InputTextField = ({label, input, meta: { touched, invalid, error }, ...custom}) => (
    <TextField
        variant={'outlined'}
        label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
    />
)

export default InputTextField