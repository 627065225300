import {
    CHANGE_FILTER_ROLE,
    CLEAR_ROLE,
    CLEAR_ROLES, CREATE_ROLE_SUCCESS, DELETE_ROLE_SUCCESS,
    REQUEST_ROLE_SUCCESS,
    REQUEST_ROLES_SUCCESS, STATUS_ROLE_SUCCESS, UPDATE_ROLE_SUCCESS
} from "../../service/actions/types/userRoleTypes";

const initialState = {
    filter: 1,
    count: 0,
    roles: [],
    role: null,
}

const userRoleReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CLEAR_ROLES:
            return {...state, roles: []}
        case CLEAR_ROLE:
            return {...state, role: null}
        case REQUEST_ROLES_SUCCESS:
            return {...state, roles: [...action.payload.items], count: action.payload.count}
        case REQUEST_ROLE_SUCCESS:
            return {...state, role: action.payload}
        case CREATE_ROLE_SUCCESS:
            return {...state, role: action.payload}
        case UPDATE_ROLE_SUCCESS:
            return {...state, role: action.payload}
        case STATUS_ROLE_SUCCESS:
            console.log(action.payload)
            const updateRoleIdx = state.roles.findIndex((role) => role.id === action.payload.id)
            return {...state, roles: [
                    ...state.roles.slice(0, updateRoleIdx),
                    action.payload,
                    ...state.roles.slice(updateRoleIdx + 1),
                ]
            }
        case DELETE_ROLE_SUCCESS:
            return {...state, roles: state.roles.filter(role => role.id !== action.payload)}
        case CHANGE_FILTER_ROLE:
            return {...state, filter: action.payload}
        default:
            return state
    }
}

export default userRoleReducer