import {compose, createStore, applyMiddleware} from "redux"
import rootReducer from "./rootReducer"
import thunkMiddleware from "redux-thunk"
import stringActionTypeMiddleware from "./middlewares/stringActionTypeMiddleware"
//import logMiddleware from "./middlewares/logMiddleware"

const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            //trace: true, traceLimit: 25
        }) : compose

const middlewares = applyMiddleware(
    thunkMiddleware,
    stringActionTypeMiddleware,
    //logMiddleware
)

const enhancer = composeEnhancers(middlewares)

const store = createStore(
    rootReducer,
    middlewares//enhancer//
)

export default store