import React from "react"
import { Switch } from "react-router-dom"
import Route from "../../service/routes/RouteWrapper"
import withSuspense from "../../service/hocs/withSuspense"
import {
        signInRoute,
        signUpRoute,
        dashboardIndexRoute,
        customersRoute,
        customerRoute,
        orderRoute,
        ordersRoute,
        orderDetailsRoute,
        orderDetailRoute,
        workflowsRoute,
        settingRoute,
        employeesRoute,
        employeeRoute,
        usersMenuPage,
        usersRoute,
        userRoute,
        rolesRoute,
        roleRoute,
        permissionsRoute,
        permissionRoute,
        homePageRoute,
        workflowRoute,
        tablesRoute,
        tableRoute,
        tableCreateRoute,
} from "../../service/routes/route-urls"
import HomePage from "../App/pages/home"
import SignIn from "../Auth/sign-in/SignInForm"
import SignUp from "../Auth/sign-up/SignUpForm"
import Dashboard from "./pages/dashboard"

const CustomersList         = React.lazy(() => import("../Calculator/Customer/CustomersList"))
const CustomerForm          = React.lazy(() => import("../Calculator/Customer/CustomerForm"))
const OrdersList            = React.lazy(() => import("../Calculator/Order/OrdersList"))
const OrderForm             = React.lazy(() => import("../Calculator/Order/OrderForm"))
const OrderDetailsList      = React.lazy(() => import("../Calculator/OrderDetails/OrderDetailsList"))
const OrderDetailForm       = React.lazy(() => import("../Calculator/OrderDetails/OrderDetailForm"))
const WorkflowsList         = React.lazy(() => import("../Calculator/Workflow/WorkflowsList"))
const WorkflowDetailsList   = React.lazy(() => import("../Calculator/WorkflowDetail/WorkflowDetailsList"))
const EmployeesList         = React.lazy(() => import("../Salary/Employee/EmployeesList"))
const EmployeeForm          = React.lazy(() => import("../Salary/Employee/EmployeeForm"))
const TableList             = React.lazy(() => import("../Salary/Table/TableList"))
const TableForm             = React.lazy(() => import("../Salary/Table/TableForm"))
const TableView             = React.lazy(() => import("../Salary/Table/TableView"))
const UsersMenuPage         = React.lazy(() => import("../Users/User/UsersMenuPage"))
const UsersList             = React.lazy(() => import("../Users/User/UsersList"))
const UserForm              = React.lazy(() => import("../Users/User/UserForm"))
const RolesList             = React.lazy(() => import("../Users/Role/RolesList"))
const RoleForm              = React.lazy(() => import("../Users/Role/RoleForm"))
const PermissionsList       = React.lazy(() => import("../Users/Permission/PermissionsList"))
const PermissionForm        = React.lazy(() => import("../Users/Permission/PermissionForm"))
const Settings              = React.lazy(() => import("../Settings/SettingsForm"))

export default function Routes() {
    return (
        <Switch>
            <Route path={homePageRoute} exact component={HomePage} />

            <Route path={signInRoute} component={SignIn} />
            <Route path={signUpRoute} component={SignUp} />

            <Route path={dashboardIndexRoute} exact component={Dashboard} isPrivate />

            <Route path={customersRoute} component={withSuspense(CustomersList)} isPrivate />
            <Route path={customerRoute} component={withSuspense(CustomerForm)} isPrivate />

            <Route path={ordersRoute} component={withSuspense(OrdersList)} isPrivate />
            <Route path={orderRoute} component={withSuspense(OrderForm)} isPrivate />

            <Route path={orderDetailsRoute} component={withSuspense(OrderDetailsList)} isPrivate />
            <Route path={orderDetailRoute} component={withSuspense(OrderDetailForm)} isPrivate />

            <Route path={workflowsRoute} component={withSuspense(WorkflowsList)} isPrivate />
            <Route path={workflowRoute} component={withSuspense(WorkflowDetailsList)} isPrivate />

            <Route path={employeesRoute} component={withSuspense(EmployeesList)} isPrivate />
            <Route path={employeeRoute} component={withSuspense(EmployeeForm)} isPrivate />

            <Route path={tablesRoute} component={withSuspense(TableList)} isPrivate />
            <Route path={tableCreateRoute} component={withSuspense(TableForm)} isPrivate />
            <Route path={tableRoute} component={withSuspense(TableView)} isPrivate />

            <Route path={usersMenuPage} exact component={withSuspense(UsersMenuPage)} isPrivate isAdmin />
            <Route path={usersRoute} component={withSuspense(UsersList)} isPrivate isAdmin />
            <Route path={userRoute} component={withSuspense(UserForm)} isPrivate isAdmin />

            <Route path={rolesRoute} component={withSuspense(RolesList)} isPrivate isAdmin />
            <Route path={roleRoute} component={withSuspense(RoleForm)} isPrivate isAdmin />

            <Route path={permissionsRoute} component={withSuspense(PermissionsList)} isPrivate isAdmin />
            <Route path={permissionRoute} component={withSuspense(PermissionForm)} isPrivate isAdmin />

            <Route path={settingRoute} component={withSuspense(Settings)} isPrivate isAdmin />

            {/* Перенаправление на страницу входа, если маршрут не существует и пользователь не аутентифицирован */}
            <Route component={SignIn} />
        </Switch>
    )
}