import React from "react"
import Typography from "@material-ui/core/Typography"
import {ButtonGroup, Grid} from "@material-ui/core"
import Button from "@material-ui/core/Button";
import styles from "../../Auth/styles";
import {homePageRoute, signInRoute, signUpRoute} from "../../../service/routes/route-urls";

export default function Dashboard(props) {

    const classes = styles()

    return (
        <Grid container spacing={4} justify="center" direction="row">
            <Grid item xs={12}>
                <Typography component="h1" variant="h5" align={"center"} gutterBottom>
                    Авторизация в СУРП
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ButtonGroup variant="contained" color="primary" aria-label="home page" fullWidth>
                    <Button onClick={() => props.history.push(signInRoute)}>Вход</Button>
                    <Button onClick={() => props.history.push(signUpRoute)}>Регистрация</Button>
                    <Button onClick={() => props.history.push(homePageRoute)}>Восстановление доступа</Button>
                </ButtonGroup>
            </Grid>
        </Grid>
    )
}