import {
    CLEAR_CUSTOMERS,
    CLEAR_CUSTOMER,
    REQUEST_CUSTOMERS_SUCCESS,
    REQUEST_CUSTOMER_SUCCESS,
    CREATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_SUCCESS,
    CHANGE_FILTER_CUSTOMER, REQUEST_CUSTOMERS_OPTIONS_SUCCESS
} from "../../service/actions/types/customerTypes"

const initialState = {
    filter: 1,
    count: 0,
    customers: [],
    options: null,
    customer: null,
}

const customerReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CLEAR_CUSTOMERS:
            return {...state, customers: []}
        case CLEAR_CUSTOMER:
            return {...state, customer: null}
        case REQUEST_CUSTOMERS_SUCCESS:
            return {...state, customers: [...action.payload.items], count: action.payload.count}
        case REQUEST_CUSTOMERS_OPTIONS_SUCCESS:
            return {...state, options: [...action.payload]}
        case REQUEST_CUSTOMER_SUCCESS:
            return {...state, customer: action.payload}
        case CREATE_CUSTOMER_SUCCESS:
            return {...state, customers: state.customers.concat([action.payload])}
            // return {...state, customers: [action.payload, ...state.customers]}
        case UPDATE_CUSTOMER_SUCCESS:
            const updateIdx = state.customers.findIndex( (customer) => customer.id === action.payload.id)
            return {...state, customers: [
                    ...state.customers.slice(0, updateIdx),
                    action.payload,
                    ...state.customers.slice(updateIdx + 1),
                    ]}
        case DELETE_CUSTOMER_SUCCESS:
            return {...state, customers: state.customers.filter(customer => customer.id !== action.payload)}
        case CHANGE_FILTER_CUSTOMER:
            return {...state, filter: action.payload}
        default:
            return state
    }
}

export default customerReducer