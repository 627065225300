export const CLEAR_TABLES                   = 'TABLE/CLEAR_ALL'
export const CLEAR_TABLE                    = 'TABLE/CLEAR_ONE'
export const REQUEST_TABLES_SUCCESS         = 'TABLE/REQUEST_ALL_SUCCESS'
export const REQUEST_TABLES_FAIL            = 'TABLE/REQUEST_ALL_FAIL'
export const REQUEST_TABLE_SUCCESS          = 'TABLE/REQUEST_ONE_SUCCESS'
export const REQUEST_TABLE_FAIL             = 'TABLE/REQUEST_ONE_FAIL'
export const CREATE_TABLE_SUCCESS           = 'TABLE/CREATE_SUCCESS'
export const CREATE_TABLE_FAIL              = 'TABLE/CREATE_FAIL'
export const UPDATE_TABLE_SUCCESS           = 'TABLE/UPDATE_SUCCESS'
export const UPDATE_TABLE_FAIL              = 'TABLE/UPDATE_FIL'
export const DELETE_TABLE_SUCCESS           = 'TABLE/DELETE_SUCCESS'
export const DELETE_TABLE_FAIL              = 'TABLE/DELETE_FAIL'
export const UPDATE_TABLE_TRACKER_SUCCESS   = 'TABLE/UPDATE_TRACKER/SUCCESS'
export const UPDATE_TABLE_TRACKER_FAIL      = 'TABLE/UPDATE_TRACKER/FAIL'