export const CLEAR_USERS            = 'USER/CLEAR_USERS'
export const CLEAR_USER             = 'USER/CLEAR_USER'
export const REQUEST_USERS_SUCCESS  = 'USER/REQUEST_USERS_SUCCESS'
export const REQUEST_USERS_FAIL     = 'USER/REQUEST_USERS_FAIL'
export const REQUEST_USER_SUCCESS   = 'USER/REQUEST_USER_SUCCESS'
export const REQUEST_USER_FAIL      = 'USER/REQUEST_USER_FAIL'
export const CREATE_USER_SUCCESS    = 'USER/CREATE_USER_SUCCESS'
export const CREATE_USER_FAIL       = 'USER/CREATE_USER_FAIL'
export const UPDATE_USER_SUCCESS    = 'USER/UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL       = 'USER/UPDATE_USER_FAIL'
export const STATUS_USER_SUCCESS    = 'USER/STATUS_USER_SUCCESS'
export const STATUS_USER_FAIL       = 'USER/STATUS_USER_FAIL'
export const DELETE_USER_SUCCESS    = 'USER/DELETE_USER_SUCCESS'
export const DELETE_USER_FAIL       = 'USER/DELETE_USER_FAIL'
export const CHANGE_FILTER_USER     = 'USER/CHANGE_USER_FILTER'