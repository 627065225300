import React from "react"
import {useDispatch, useSelector} from "react-redux"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import {dashboardIndexRoute, homePageRoute} from "./route-urls"
import DashboardLayout from "../../components/App/layouts/dashboard/dashboard-layout"
import AuthLayout from "../../components/App/layouts/auth/auth-layout"
import {signOutAction} from "../actions/authAction";

export default function RouteWrapper({component: Component, isPrivate, isAdmin, ...rest}) {

    const dispatch  = useDispatch()
    const signed    = useSelector(store => store.authStore.isAuth)
    const roles     = useSelector(store => store.authStore.userRoles)
    const expired   = useSelector(store => store.authStore.expiredTime)

    // При попытке получить доступ к приватному маршруту без аутентификации, перенаправление на страницу входа
    if (isPrivate && !signed)
        return <Redirect to={homePageRoute}/>

    // Если приватный маршрут а токен протух, сбрасываем аутенификацию
    if (isPrivate && expired)
        if (Math.round(Date.now() / 1000) > (expired - 1600))
            dispatch(signOutAction());

    //перенаправление при попытке получить доступ к не приватному маршруту (SignIn или SignUp) после аутентификации.
    if (!isPrivate && signed)
        return <Redirect to={dashboardIndexRoute}/>

    //Закомментировать 3 строки для назначения ролей:
    const adminRole = roles && roles.includes("admin") || null

    if (isPrivate && isAdmin && signed && !adminRole)
        return <Redirect to={dashboardIndexRoute}/>

    const Layout = signed ? DashboardLayout : AuthLayout

    // Иначе перенаправление на желаемый маршрут
    return (
        <Route {...rest} render={props =>
            <Layout>
                <Component {...props} />
            </Layout>
        } />
    )
}

RouteWrapper.propTypes = {
    isPrivate: PropTypes.bool,
    //component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
}

RouteWrapper.defaultProps = {
    isPrivate: false
}