import React from "react"
import Link from '@material-ui/core/Link'
import Typography from "@material-ui/core/Typography"
import styles from "./deps/styles"

export default function Copyright() {

    const classes = styles()

    return (
        <footer className={classes.footer}>
            <Typography variant="body2" color="textSecondary" align="center">
                {'© Все права защищены '}
                <Link color="inherit" href="https://www.rvkvent.ru/">
                    ООО "РВК Вент"
                </Link>{' 2014-'}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </footer>
    )
}