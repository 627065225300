import React from "react"
import {BrowserRouter} from 'react-router-dom'
import Routes from './Routes'
import {Provider} from "react-redux"
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles'
import {ruRU} from '@material-ui/core/locale'
import "./common/styles/style.css"
import store from "../../redux/store"

const theme = createMuiTheme({
    palette: {
        primary: {main: '#1976d2'},
        // secondary: { main: '#11cb5f' },
        //danger: { main: '#dc5e3e' },
    },
    typography: {useNextVariants: true},
}, ruRU)

export default function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <Routes />
                </ThemeProvider>
            </BrowserRouter>
        </Provider>
    )
}