import {
    CLEAR_USERS, CLEAR_USER, REQUEST_USERS_SUCCESS, REQUEST_USER_SUCCESS,
    DELETE_USER_SUCCESS, CHANGE_FILTER_USER, STATUS_USER_SUCCESS
} from "../../service/actions/types/userTypes"

const initialState = {
    filter: 1,
    count: 0,
    users: [],
    user: null,
}

const userReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CLEAR_USER:
            return {...state, user: null}
        case CLEAR_USERS:
            return {...state, users: []}
        case REQUEST_USERS_SUCCESS:
            return {...state, users: [...action.payload.items], count: action.payload.count}
        case REQUEST_USER_SUCCESS:
            return {...state, user: action.payload}
        case STATUS_USER_SUCCESS:
            console.log(action.payload)
            const updateUserIdx = state.users.findIndex((user) => user.id === action.payload.id)
            return {...state, users: [
                    ...state.users.slice(0, updateUserIdx),
                    action.payload,
                    ...state.users.slice(updateUserIdx + 1),
                ]
            }
        case DELETE_USER_SUCCESS:
            return {...state, users: state.users.filter(user => user.id !== action.payload)}
        case CHANGE_FILTER_USER:
            return {...state, filter: action.payload}
        default:
            return state
    }
}

export default userReducer