import React from 'react'
import {Link as RouterLink} from "react-router-dom"
import {
    dashboardIndexRoute,
    customersRoute,
    ordersRoute,
    workflowsRoute,
    tablesRoute,
    payrollsRoute,
    employeesRoute,
    usersMenuPage,
    kitsRoute,
    settingGetTypeRoute,
} from "../../../../service/routes/route-urls"
import {MARKUP_MENU} from "../../../Settings/config/menu-types"
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import List from "@material-ui/core/List"
import {
    LocalShipping,
    People,
    Home,
    LockOpen,
    Settings,
    ViewList,
    PeopleOutline,
    LocalAtm,
    DateRange,
    Build
} from "@material-ui/icons"

const ListItemLink = (props) => {
    const { icon, primary, title, to } = props

    const renderLink = React.useMemo(
        () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
        [to],
    )

    return (
        <li>
            <ListItem button component={renderLink} title={title}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    )
}

export const HomeMenu = (
    <List aria-label="main mailbox folders">
        <ListItemLink to={dashboardIndexRoute} primary="Главная" title="Главная" icon={<Home/>}/>
    </List>
)

export const CalculatorMenu = (
    <List aria-label="main mailbox folders">
        <ListItemLink to={customersRoute} primary="Контрагенты" title="Контрагенты" icon={<People/>}/>
        <ListItemLink to={ordersRoute} primary="Заказы" title="Заказы" icon={<ViewList/>}/>
        <ListItemLink to={workflowsRoute} primary="Наряды" title="Наряды" icon={<LocalShipping/>}/>
    </List>
)

export const SalaryMenu = (
    <List>
        <ListItemLink to={tablesRoute} primary="Табеля" title="Табеля" icon={<DateRange/>}/>
        <ListItemLink to={payrollsRoute} primary="Ведомости" title="Ведомости" icon={<LocalAtm/>}/>
        <ListItemLink to={employeesRoute} primary="Сотрудники" title="Сотрудники" icon={<PeopleOutline/>}/>
    </List>
)

export const AdminMenu = (
    <List>
        <ListItemLink to={usersMenuPage} primary="Пользователи" title="Пользователи" icon={<LockOpen/>}/>
        <ListItemLink to={settingGetTypeRoute(MARKUP_MENU)} primary="Настройки" title="Настройки" icon={<Settings/>}/>
    </List>
)