export const CLEAR_ROLES            = 'ROLE/CLEAR_ROLES'
export const CLEAR_ROLE             = 'ROLE/CLEAR_ROLE'
export const REQUEST_ROLES_SUCCESS  = 'ROLE/REQUEST_ROLES_SUCCESS'
export const REQUEST_ROLES_FAIL     = 'ROLE/REQUEST_ROLES_FAIL'
export const REQUEST_ROLE_SUCCESS   = 'ROLE/REQUEST_ROLE_SUCCESS'
export const REQUEST_ROLE_FAIL      = 'ROLE/REQUEST_ROLE_FAIL'
export const CREATE_ROLE_SUCCESS    = 'ROLE/CREATE_ROLE_SUCCESS'
export const CREATE_ROLE_FAIL       = 'ROLE/CREATE_ROLE_FAIL'
export const UPDATE_ROLE_SUCCESS    = 'ROLE/UPDATE_ROLE_SUCCESS'
export const UPDATE_ROLE_FAIL       = 'ROLE/UPDATE_ROLE_FAIL'
export const STATUS_ROLE_SUCCESS    = 'ROLE/STATUS_ROLE_SUCCESS'
export const STATUS_ROLE_FAIL       = 'ROLE/STATUS_ROLE_FAIL'
export const DELETE_ROLE_SUCCESS    = 'ROLE/DELETE_ROLE_SUCCESS'
export const DELETE_ROLE_FAIL       = 'ROLE/DELETE_ROLE_FAIL'
export const CHANGE_FILTER_ROLE     = 'ROLE/CHANGE_ROLE_FILTER'