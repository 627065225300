export const CLEAR_ORDERS                   = 'ORDER/CLEAR_ORDERS'
export const CLEAR_ORDER                    = 'ORDER/CLEAR_ORDER'
export const REQUEST_ORDERS_SUCCESS         = 'ORDER/REQUEST_ORDERS_SUCCESS'
export const REQUEST_ORDERS_FAIL            = 'ORDER/REQUEST_ORDERS_FAIL'
export const REQUEST_ORDER_SUCCESS          = 'ORDER/REQUEST_ORDER_SUCCESS'
export const REQUEST_ORDER_FAIL             = 'ORDER/REQUEST_ORDER_FAIL'
export const CREATE_ORDER_SUCCESS           = 'ORDER/CREATE_ORDER_SUCCESS'
export const CREATE_ORDER_FAIL              = 'ORDER/CREATE_ORDER_FAIL'
export const COPY_ORDER_SUCCESS             = 'ORDER/COPY_ORDER_SUCCESS'
export const COPY_ORDER_FAIL                = 'ORDER/COPY_ORDER_FAIL'
export const UPDATE_ORDER_SUCCESS           = 'ORDER/UPDATE_ORDER_SUCCESS'
export const UPDATE_ORDER_FAIL              = 'ORDER/UPDATE_ORDER_FAIL'
export const TOGGLE_STATUS_ORDER_SUCCESS    = 'ORDER/TOGGLE_STATUS_ORDER_SUCCESS'
export const TOGGLE_STATUS_ORDER_FAIL       = 'ORDER/TOGGLE_STATUS_ORDER_FAIL'
export const DELETE_ORDER_SUCCESS           = 'ORDER/DELETE_ORDER_SUCCESS'
export const DELETE_ORDER_FAIL              = 'ORDER/DELETE_ORDER_FAIL'
export const CHANGE_ORDER_CUSTOMER_SUCCESS  = 'ORDER/CHANGE_ORDER_CUSTOMER_SUCCESS'
export const CHANGE_FILTER_ORDER            = 'ORDER/CHANGE_ORDER_FILTER'
export const ADJUSTMENT_AMOUNT_ORDER        = 'ORDER/ADJUSTMENT_AMOUNT_ORDER'