const dashboardRoute                = 'dashboard'

export const homePageRoute          = '/'
export const signInRoute            = '/login'
export const signUpRoute            = '/sign-up'
export const dashboardIndexRoute    = `/${dashboardRoute}`
export const profileRoute           = `/${dashboardRoute}/profile`

export const customersRoute         = `/${dashboardRoute}/customers`
export const customerRoute          = `/${dashboardRoute}/customer/:id`
export const customerGetIdRoute     =  id => `/${dashboardRoute}/customer/${id}`

export const ordersRoute            = `/${dashboardRoute}/orders`
export const orderRoute             = `/${dashboardRoute}/order/:id`
export const orderGetIDRoute        =  id => `/${dashboardRoute}/order/${id}`

export const orderDetailsRoute      = `/${dashboardRoute}/order-details`
export const orderDetailRoute       = `/${dashboardRoute}/order-detail/:order/:type/:id`
export const orderDetailGetIDRoute  =  (orderId, type, id) => `/${dashboardRoute}/order-detail/${orderId}/${type}/${id}`

export const kitsRoute              = `/${dashboardRoute}/kits`
export const kitRoute               = `/${dashboardRoute}/kit/:orderId/:id`
export const kitGetIDRoute          =  (orderId, id) => `/${dashboardRoute}/kit/${orderId}/${id}`

export const kitDetailsRoute        = `/${dashboardRoute}/kit-details`
export const kitDetailRoute         = `/${dashboardRoute}/kit-detail/:kit/:id`
export const kitDetailGetIDRoute    =  (kitId, id) => `/${dashboardRoute}/kit-detail/${kitId}/${id}`

export const workflowsRoute         = `/${dashboardRoute}/workflows`
export const workflowRoute          = `/${dashboardRoute}/workflow/:id`
export const workflowGetIDRoute     =  id => `/${dashboardRoute}/workflow/${id}`

export const tablesRoute            = `/${dashboardRoute}/tables`
export const tableCreateRoute       = `/${dashboardRoute}/table-create`
export const tableRoute             = `/${dashboardRoute}/table/:id`
export const tableGetIDRoute        =  id => `/${dashboardRoute}/table/${id}`


export const payrollsRoute          = `/${dashboardRoute}/payrolls`
export const payrollRoute           = `/${dashboardRoute}/payroll/:id`
export const payrollGetIDRoute      =  id => `/${dashboardRoute}/payroll/${id}`

export const employeesRoute         = `/${dashboardRoute}/employees`
export const employeeRoute          = `/${dashboardRoute}/employee/:id`
export const employeeGetIDRoute     =  id => `/${dashboardRoute}/employee/${id}`

export const usersMenuPage          = `/${dashboardRoute}/rbac`
export const usersRoute             = `/${dashboardRoute}/rbac/users`
export const userRoute              = `/${dashboardRoute}/rbac/user/:id`
export const userGetIDRoute         =  id => `/${dashboardRoute}/rbac/user/${id}`

export const rolesRoute             = `/${dashboardRoute}/rbac/roles`
export const roleRoute              = `/${dashboardRoute}/rbac/role/:id`
export const roleGetIDRoute         =  id => `/${dashboardRoute}/rbac/role/${id}`

export const permissionsRoute       = `/${dashboardRoute}/rbac/permissions`
export const permissionRoute        = `/${dashboardRoute}/rbac/permission/:id`
export const permissionGetIDRoute   =  id => `/${dashboardRoute}/rbac/permission/${id}`

export const settingsRoute          = `/${dashboardRoute}/settings`
export const settingRoute           = `/${dashboardRoute}/setting/:type`
export const settingGetTypeRoute    = type => `/${dashboardRoute}/setting/${type}`