import React from "react"
import Button from "@material-ui/core/Button"
import {useDispatch} from "react-redux"
import {signInAction} from "../../../service/actions/authAction"
import {Field, initialize, reduxForm} from "redux-form"
import InputTextField from "../../App/common/redux-form-fields/InputTextField"
import signInFormValidator from "./signInFormValidator"
import {homePageRoute} from "../../../service/routes/route-urls"
import Avatar from "@material-ui/core/Avatar"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import Typography from "@material-ui/core/Typography"
import {ButtonGroup} from "@material-ui/core";
import styles from "../styles"

const form = 'signIn'

const SignInForm = (props) => {
    const classes = styles()
    const dispatch = useDispatch()
    const {handleSubmit, pristine, reset, submitting} = props
/*
    const data = {
        email: 'admin@rvkvent.ru',
        password: '09011983',
        remember: '0',
    }

    React.useEffect(() => {
        dispatch(initialize(form, data))
    }, [dispatch])
*/

    const submitHandler = (values) => {
        dispatch(signInAction(values))
    }

    return (
        <React.Fragment>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Вход
            </Typography>
            <form onSubmit={handleSubmit(submitHandler)} className={classes.form}>
                <Field
                    required
                    name="email"
                    component={InputTextField}
                    label="Емайл"
                    type="email"
                    //autoComplete="off"
                    margin="normal"
                    fullWidth
                    autoFocus
                />
                <Field
                    required
                    name="password"
                    component={InputTextField}
                    label="Пароль"
                    type="password"
                    autoComplete="off"
                    margin="normal"
                    fullWidth
                />
                {/*<FormControlLabel*/}
                {/*    control={<Checkbox value="remember" color="primary"/>}*/}
                {/*    label="Включить вдохновение"*/}
                {/*/>*/}
                <ButtonGroup variant="contained" color="primary" aria-label="home page" fullWidth>
                    <Button type="submit">Вход</Button>
                    <Button type="button" onClick={() => props.history.push(homePageRoute)}>Назад</Button>
                </ButtonGroup>
            </form>
        </React.Fragment>
    )
}

export default reduxForm({
    form: form,
    signInFormValidator,
})(SignInForm)