import {v4 as uuid} from 'uuid'
import {SET_ALERT, REMOVE_ALERT, SHOW_LOADER, HIDE_LOADER} from "./types/appTypes"
import axios from "./api/api";

export function showAlertAction(message, type) {
    return dispatch => {
        const id = uuid()
        dispatch({type: SET_ALERT, payload: {id, type, message}})
        setTimeout(() => {
            dispatch({type: REMOVE_ALERT, payload: id})
        }, 2000)
    }
}

export function showLoaderAction() {
    return dispatch => {
        dispatch({type: SHOW_LOADER})
    }
}

export function hideLoaderAction() {
    return dispatch => {
        dispatch({type: HIDE_LOADER})
    }
}

