import {
    CHANGE_FILTER_PERMISSION,
    CLEAR_PERMISSION,
    CLEAR_PERMISSIONS, DELETE_PERMISSION_SUCCESS, REQUEST_PERMISSION_SUCCESS,
    REQUEST_PERMISSIONS_SUCCESS, STATUS_PERMISSION_SUCCESS
} from "../../service/actions/types/userRolePermissionTypes";

const initialState = {
    filter: 1,
    count: 0,
    permissions: [],
    permission: null,
}

const userRoleReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CLEAR_PERMISSIONS:
            return {...state, permissions: []}
        case CLEAR_PERMISSION:
            return {...state, permission: null}
        case REQUEST_PERMISSIONS_SUCCESS:
            return {...state, permissions: [...action.payload.items], count: action.payload.count}
        case REQUEST_PERMISSION_SUCCESS:
            return {...state, permission: action.payload}
        case STATUS_PERMISSION_SUCCESS:
            console.log(action.payload)
            const updatePermissionIdx = state.permissions.findIndex((permission) => permission.id === action.payload.id)
            return {...state, permissions: [
                    ...state.permissions.slice(0, updatePermissionIdx),
                    action.payload,
                    ...state.permissions.slice(updatePermissionIdx + 1),
                ]
            }
        case DELETE_PERMISSION_SUCCESS:
            return {...state, permissions: state.permissions.filter(permission => permission.id !== action.payload)}
        case CHANGE_FILTER_PERMISSION:
            return {...state, filter: action.payload}
        default:
            return state
    }
}

export default userRoleReducer