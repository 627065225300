export const CLEAR_PERMISSIONS              = 'PERMISSION/CLEAR_PERMISSIONS'
export const CLEAR_PERMISSION               = 'PERMISSION/CLEAR_PERMISSION'
export const REQUEST_PERMISSIONS_SUCCESS    = 'PERMISSION/REQUEST_PERMISSIONS_SUCCESS'
export const REQUEST_PERMISSIONS_FAIL       = 'PERMISSION/REQUEST_PERMISSIONS_FAIL'
export const REQUEST_PERMISSION_SUCCESS     = 'PERMISSION/REQUEST_PERMISSION_SUCCESS'
export const REQUEST_PERMISSION_FAIL        = 'PERMISSION/REQUEST_PERMISSION_FAIL'
export const CREATE_PERMISSION_SUCCESS      = 'PERMISSION/CREATE_PERMISSION_SUCCESS'
export const CREATE_PERMISSION_FAIL         = 'PERMISSION/CREATE_PERMISSION_FAIL'
export const UPDATE_PERMISSION_SUCCESS      = 'PERMISSION/UPDATE_PERMISSION_SUCCESS'
export const UPDATE_PERMISSION_FAIL         = 'PERMISSION/UPDATE_PERMISSION_FAIL'
export const STATUS_PERMISSION_SUCCESS      = 'PERMISSION/STATUS_PERMISSION_SUCCESS'
export const STATUS_PERMISSION_FAIL         = 'PERMISSION/STATUS_PERMISSION_FAIL'
export const DELETE_PERMISSION_SUCCESS      = 'PERMISSION/DELETE_PERMISSION_SUCCESS'
export const DELETE_PERMISSION_FAIL         = 'PERMISSION/DELETE_PERMISSION_FAIL'
export const CHANGE_FILTER_PERMISSION       = 'PERMISSION/CHANGE_PERMISSION_FILTER'