import React from "react"
import Typography from "@material-ui/core/Typography"
import CardContent from "@material-ui/core/CardContent"
import Card from "@material-ui/core/Card"
import {Grid} from "@material-ui/core"
import {NavLink} from "react-router-dom"
import {
    customersRoute,
    employeesRoute,
    ordersRoute,
    payrollsRoute,
    settingsRoute,
    tablesRoute,
    workflowsRoute
} from "../../../service/routes/route-urls"
import {
    DateRange,
    ShoppingCart,
    Event,
    People,
    PeopleOutline,
    Settings,
    LocalShipping
} from "@material-ui/icons";


const linkMenuItem = (route, text, icon) => (
    <Grid item md={3} xs={6}>
        <NavLink
            to={route}
            style={{textDecoration: 'none'}}
        >
            <Card>
                <CardContent style={{padding: '60px 0'}}>
                    <Typography component="span" align="center" display="block" gutterBottom>{icon}</Typography>
                    <Typography variant="subtitle1" component="span" align="center" display="block" style={{fontSize: '20px', fontWeight: 'bold'}}>{text}</Typography>
                </CardContent>
            </Card>
        </NavLink>
    </Grid>
)

export default function Dashboard(props) {

    return (
        <>
            <Grid container spacing={3} direction="row">
                <Grid item xs={12}>
                    <Typography variant="h4" component="h4" align="center" gutterBottom>Главное меню</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row">
                {linkMenuItem(customersRoute,'Контрагенты', <People/>)}
                {linkMenuItem(ordersRoute,'Заказы', <ShoppingCart/>)}
                {linkMenuItem(workflowsRoute,'Наряды', <LocalShipping/>)}
                {linkMenuItem(tablesRoute,'Табеля', <DateRange/>)}
                {linkMenuItem(payrollsRoute,'Ведомости', <Event/>)}
                {linkMenuItem(employeesRoute,'Работники', <PeopleOutline/>)}
                {linkMenuItem(settingsRoute,'Настройки', <Settings/>)}
            </Grid>
        </>
    )
}