import {
    CHANGE_FILTER_WORKFLOW,
    CHANGE_SORT_WORKFLOW,
    CHANGE_WORKFLOW_FIELD_SUCCESS,
    CLEAR_WORKFLOW,
    CLEAR_WORKFLOWS,
    DELETE_WORKFLOW_SUCCESS,
    REQUEST_WORKFLOW_SUCCESS,
    REQUEST_WORKFLOWS_SUCCESS,
    UPDATE_WORKFLOW_SUCCESS
} from "../../service/actions/types/workflowTypes"


const sorter = (() => {
    let sorter = localStorage.getItem('sorter');
    if (! sorter) {
        sorter = 0;
    }
    console.log('FNC: ', sorter)
    return Number(sorter)
})()
const filter = (() => {
    let filter = localStorage.getItem('filter');
    if (! filter) {
        filter = 1;
    }
    return Number(filter)
})()

const initialState = {
    sorter: sorter,
    filter: filter,
    count: 0,
    workflows: [],
    workflowDetails: [],
}

const workflowReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CLEAR_WORKFLOW:
            return {...state, workflowDetails: []}
        case CLEAR_WORKFLOWS:
            return {...state, workflows: []}
        case REQUEST_WORKFLOWS_SUCCESS:
            return {...state, workflows: [...action.payload.items], count: action.payload.count}
        case REQUEST_WORKFLOW_SUCCESS:
            return {...state, workflowDetails: action.payload}
        case UPDATE_WORKFLOW_SUCCESS:
            const updateIdx = state.workflows.findIndex( (workflow) => workflow.id === action.payload.id)
            return {
                ...state, workflows: [
                    ...state.workflows.slice(0, updateIdx),
                    action.payload,
                    ...state.workflows.slice(updateIdx + 1),
                ]
            }
        case CHANGE_WORKFLOW_FIELD_SUCCESS:
            const updateFieldsIdx = state.workflows.findIndex( (workflow) => workflow.id === action.payload.id)
            return {
                ...state, workflows: [
                    ...state.workflows.slice(0, updateFieldsIdx),
                    action.payload,
                    ...state.workflows.slice(updateFieldsIdx + 1),
                ]
            }
        case DELETE_WORKFLOW_SUCCESS:
            return {...state, workflows: state.workflows.filter(workflow => workflow.id !== action.payload)}
        case CHANGE_FILTER_WORKFLOW:
            return {...state, filter: action.payload}
        case CHANGE_SORT_WORKFLOW:
            return {...state, sorter: action.payload}
        default:
            return state
    }
}

export default workflowReducer