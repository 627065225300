import React from "react"
import {useSelector} from "react-redux"
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const AlertComponent = () => {
    const [open, setOpen] = React.useState(true)
    const alerts = useSelector(state => state.appStore.alerts)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {return}
        setOpen(false)
    }

    return (
        alerts !== [] && alerts.length > 0 && alerts.map((alert) => (
            <Snackbar
                key={alert.id}
                open={open}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={alert.type}>
                    {alert.message}
                </Alert>
            </Snackbar>
        ))
    )
}

export default AlertComponent