import {
    LOGIN_AUTH_SUCCESS,CLEAR_AUTH
} from "../../service/actions/types/authTypes"

const token = localStorage.getItem('token_api') || null
const tokenExpire = localStorage.getItem('token_expire') || null
const userName = localStorage.getItem('user_name') || null
const userRoles = localStorage.getItem('user_roles') || null

const initialState = {
    isAuth: (!(typeof token === 'undefined' || token === null || token === '')),
    expiredTime: tokenExpire,
    userName: userName,
    userRoles: userRoles,
}

const authReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CLEAR_AUTH:
            return {...state, isAuth: false, user: null, expiredTime: null}
        case LOGIN_AUTH_SUCCESS:
            return {...state,
                isAuth: true,
                expiredTime: action.payload.exp,
                userName: action.payload.user.name,
                userRoles: action.payload.user.roles,
            }
        default:
            return state
    }
}

export default authReducer