import axios from "axios"

const axiosInstance = axios.create({
    baseURL: 'http://api.rvkvent.ru/v1',
    //baseURL: 'http://localhost:8002/v1',
    responseType: 'json',
    timeout: 8000,
    headers: {
        'Accept':           'application/json',
        'Content-Type':     'application/json',
    },
    validateStatus: function (status) {
        return status < 300; // Разрешить, есть статус < 500
    },
})

//axiosInstance.defaults.headers.common.Authorization = localStorage.getItem('token')

// Перехват запроса перед отправкой
axiosInstance.interceptors.request.use(function (request) {
    request.headers['Authorization'] = localStorage.getItem('token_api') || null
    return request
}, function (error) {
    // Do something with request error
    console.error(error)
    return Promise.reject(error)
})


// Перехват ответа для обработки
axiosInstance.interceptors.response.use(function (response) {
    if (response.status <= 200 && response.status < 400)
        if (response.data)
            return Promise.resolve(response.data)
    //return response.data
}, function (error) {
    //return error
    return Promise.reject(error)
})

let CancelToken = axios.CancelToken.source()
axiosInstance.defaults.cancelToken = CancelToken.token

export default axiosInstance