export const CLEAR_EMPLOYEES                    = 'EMPLOYEE/CLEAR_ALL'
export const CLEAR_EMPLOYEE                     = 'EMPLOYEE/CLEAR_ONE'
export const REQUEST_EMPLOYEES_SUCCESS          = 'EMPLOYEE/REQUEST_ALL_SUCCESS'
export const REQUEST_EMPLOYEES_FAIL             = 'EMPLOYEE/REQUEST_ALL_FAIL'
export const REQUEST_EMPLOYEE_SUCCESS           = 'EMPLOYEE/REQUEST_ONE_SUCCESS'
export const REQUEST_EMPLOYEE_FAIL              = 'EMPLOYEE/REQUEST_ONE_FAIL'
export const CREATE_EMPLOYEE_SUCCESS            = 'EMPLOYEE/CREATE_SUCCESS'
export const CREATE_EMPLOYEE_FAIL               = 'EMPLOYEE/CREATE_FAIL'
export const UPDATE_EMPLOYEE_SUCCESS            = 'EMPLOYEE/UPDATE_SUCCESS'
export const UPDATE_EMPLOYEE_FAIL               = 'EMPLOYEE/UPDATE_FIL'
export const DELETE_EMPLOYEE_SUCCESS            = 'EMPLOYEE/DELETE_SUCCESS'
export const DELETE_EMPLOYEE_FAIL               = 'EMPLOYEE/DELETE_FAIL'
export const CHANGE_FILTER_EMPLOYEE             = 'EMPLOYEE/CHANGE_FILTER'