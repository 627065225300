import {
    CLEAR_ORDERS,
    CLEAR_ORDER,
    REQUEST_ORDERS_SUCCESS,
    REQUEST_ORDER_SUCCESS,
    CHANGE_ORDER_CUSTOMER_SUCCESS,
    DELETE_ORDER_SUCCESS,
    CHANGE_FILTER_ORDER,
    ADJUSTMENT_AMOUNT_ORDER,
    COPY_ORDER_SUCCESS,
    TOGGLE_STATUS_ORDER_SUCCESS, UPDATE_ORDER_SUCCESS
} from "../../service/actions/types/orderTypes"
import {CREATE_WORKFLOW_SUCCESS} from "../../service/actions/types/workflowTypes";

const initialState = {
    filter: 1,
    count: 0,
    orders: [],
    order: null,
}

const orderReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CLEAR_ORDERS:
            return {...state, orders: []}
        case CLEAR_ORDER:
            return {...state, order: null}
        case REQUEST_ORDER_SUCCESS:
            return {...state, order: action.payload}
        case REQUEST_ORDERS_SUCCESS:
            return {...state, orders: [...action.payload.items], count: action.payload.count}
        case CHANGE_ORDER_CUSTOMER_SUCCESS:
            return {
                ...state,
                order: {
                    ...state.order,
                    customer_id: action.payload.customer_id,
                    customer_title: action.payload.customer_title
                }
            }
        /*
        case CREATE_ORDER_SUCCESS:
            return {...state, orders: state.orders.concat([action.payload])}
            return {...state, orders: [action.payload, ...state.orders]}
        case UPDATE_ORDER_SUCCESS:
            const updateIdx = state.orders.findIndex((order) => order.id === action.payload.id)
            return {...state, orders: [
                    ...state.orders.slice(0, updateIdx),
                    action.payload,
                    ...state.orders.slice(updateIdx + 1),
                ]
            }
        */
        case UPDATE_ORDER_SUCCESS:
            const updatedId = state.orders.findIndex((order) => order.id === action.payload.id)
            return {
                ...state, orders: [
                    ...state.orders.slice(0, (updatedId)),
                    action.payload,
                    ...state.orders.slice(updatedId + 1),
                ]
            }
        case COPY_ORDER_SUCCESS:
            const copyId = state.orders.findIndex((order) => order.id === action.payload.copyId)
            return {
                ...state, orders: [
                    ...state.orders.slice(0, (copyId)),
                    action.payload.data,
                    ...state.orders.slice(copyId),
                ]
            }
        case TOGGLE_STATUS_ORDER_SUCCESS:
            const toggleId = state.orders.findIndex((order) => order.id === action.payload.id)
            if (action.payload.status === 1) // Если активна - то добавляем в массив
                return {
                    ...state, orders: [
                        ...state.orders.slice(0, (toggleId)),
                        action.payload,
                        ...state.orders.slice(toggleId),
                    ]
                }
            else // ... иначе удаляем из массива
                return {
                    ...state, orders: [
                        ...state.orders.slice(0, (toggleId)),
                        ...state.orders.slice(toggleId - 1),
                    ]
                }
        case CREATE_WORKFLOW_SUCCESS:
            const updateWorkflowIdx = state.orders.findIndex((order) => order.id === action.payload.orderId)
            let order = state.orders[updateWorkflowIdx]
            order.workflow = action.payload.workflowId
            return {...state, orders: [
                    ...state.orders.slice(0, updateWorkflowIdx),
                    order,
                    ...state.orders.slice(updateWorkflowIdx + 1),
                ]
            }
        case DELETE_ORDER_SUCCESS:
            return {...state, orders: state.orders.filter(order => order.id !== action.payload)}
        case CHANGE_FILTER_ORDER:
            return {...state, filter: action.payload}
        case ADJUSTMENT_AMOUNT_ORDER:
            return {...state, order: {...state.order, amount: action.payload}}
        default:
            return state
    }
}

export default orderReducer